@font-face {
  font-family: 'LatoLatin';
  font-weight: normal;
  src: url('/assets/fonts/LatoLatin-Regular.ttf')
}

@font-face {
  font-family: 'LatoLatin';
  font-weight: bold;
  src: url('/assets/fonts/LatoLatin-Bold.ttf')
}

@font-face {
  font-family: 'LatoLatin';
  font-weight: 600;
  src: url('/assets/fonts/LatoLatin-Semibold.ttf')
}

@font-face {
  font-family: 'FE-Fontnormal';
  font-weight: 600;
  src: url('/assets/fonts/FE-Fontnormal.ttf')
}
html, body {
  background-color: #fff;
  color: #636b6f;
  font-family: 'LatoLatin', sans-serif;
  height: 100vh;
  margin: 0;
}

#app{
  padding-top:90px
}

@media (min-width:768px){
  #app{
    padding-top:0
  }
}

@media (min-width:768px){
  #app {
    margin-left:270px;
  }
}

#app {
  height: 100%;
  min-height: calc(100vh - 39px);
}

.page-container{
  height: 100%;
  min-height: calc(100vh);
  padding-top: 147px;
}

.flex-start {
  align-items: flex-start;
  display: flex;
  justify-content: center;
}

.position-ref {
  position: relative;
}

.dropdown-toggle.btn.btn-main{
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown-item{
  max-width: 1068px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'LatoLatin';
  font-size: 14px;
  color: #363636;
}

.top-right {
  position: absolute;
  right: 10px;
  top: 18px;
}

.content-sm {
  text-align: center;
  width: 360px;
}

.content-md {
  text-align: center;
  max-width: 481px;
}

.alert-danger {
  background-color: #ffebe6;
  font-family: 'LatoLatin';
  font-size: 16px;
  color: #f11500;
  font-weight: bold;
  text-align: left;
}

.alert-danger ul{
  padding-left: 24px;
  margin-bottom: 0px;
}

.alert-danger ul li{
  padding-left: 12px;
}

.title {
  font-size: 84px;
}

.links>a {
  color: #636b6f;
  padding: 0 25px;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: .1rem;
  text-decoration: none;
  text-transform: uppercase;
}

.card-header{
  line-height: 32px;
  font-weight: bold;
}

.m-b-md {
  margin-bottom: 30px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance:textfield;
}

.navbar-brand {
  margin: 0 auto;
}

.top-nav-item{
  margin: 0;
  line-height: 0.94;
  letter-spacing: normal;
  color: #3d3d3d;
  font-size: 16px;
  text-decoration: none;
  font-family: 'LatoLatin';
  font-weight: bold;
  font-stretch: normal;
}

.nav-item a{
  font-size: 14px;
}

.nav-item a:hover{
  cursor: pointer;
}
.row {
  margin-right: 0;
  margin-left: 0;
}

.row.with-back-button {
  margin-right: 6px;
}

@media (min-width:768px){
  .navbar.navbar-top{
    right:auto;
    min-height: 71px;
    background-color: white;
    width:100%;
    border-bottom: solid 1px #e8e6e6;
  }
}

.hidden-link:hover {
  text-decoration: none;
}
.btn{
  padding: .32rem .75rem;
  margin: 4px 0;
}

.btn.left-aligned{
  margin-right: 20px;
}

.btn.right-aligned{
  margin-left: 20px;
}

.btn-find-command{
  background-image: url('/assets/images/search-icon@2x.png');
  background-size: 19px auto;
  background-position: 17px 12px;
  margin-right: 21px;
  width: 230px;
}

.btn-login{
  background-image: url('/assets/images/lock-icon@2x.png');
  background-size: 19px auto;
  background-position: 17px 12px;
  width: 200px;
}

.btn-find-command > span, .btn-login > span{
  margin-left: 15px;
}

.btn-main{
  height: 44px;
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 #dddddd;
  background-color: #039a57;
  line-height: 2.1;
  font-size: 16px;
  background-repeat: no-repeat;
  border: none;
  color: #fff;
}

.btn-main.disabled:hover{
  background-color: #6c757d;
  color: #fff;
}

.btn-main:not(:disabled):not(.disabled):focus, .btn-main:not(:disabled):not(.disabled):active{
  background-color: #0D8E4B;
  color: #fff;
}

.btn-delete.btn-secondary:not(:disabled):not(.disabled):focus, .btn-delete.btn-secondary:not(:disabled):not(.disabled):active, .btn-delete.btn-secondary:not(:disabled):not(.disabled):active:focus{
  background-color: #C40D0F;
}

.btn-cancel.btn-secondary:hover{
  background-color: #555555;
}
.btn-cancel.btn-secondary:not(:disabled):not(.disabled):focus, .btn-cancel.btn-secondary:not(:disabled):not(.disabled):active, .btn-cancel.btn-secondary:not(:disabled):not(.disabled):active:focus{
  background-color: #484848;
}

.btn-main:hover {
  background-color: #00813e;
  color: #fff;
}

.lf-main-text{
  text-align: left;
  font-family: 'LatoLatin';
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}
.form-name-text{
  margin-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
}
.page-container .lf-main-text{
  margin-bottom: 31px;
}
.lf-logo{
  padding-right: 20px;
  height: 114px;
  border-right: solid 1px #c8c8c8;
  padding-top: 32px;
}
.lf-logo img{
  width: 140px;
  height: 48px;
}
.vinci-logo{
  border-right: solid 1px #c8c8c8;
  padding-right: 70px;
  padding-bottom: 50px;
}
.vinci-logo img{
  max-width: 530px;
  float: right;
  margin-right: 30px;
}
.clearfix{
  clear: both;
}
.vinci-logo img.truckLogo{
  max-width: 778px;
  margin-top: 52px;
  margin-right: 0;
}
.findOrderBtn{
  margin-bottom: 30px;
}
.page-container.authPage{
  padding-top: 120px;
  padding-bottom: 60px;
}
.page-container.searchPage{
  padding: 20px 0 0;
}
.authFormWrapper{
  padding-top: 160px;
}
.lf-logo-text{
  font-family: 'LatoLatin';
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #df6951;
  margin-left: 20px;
}
.lf-logo-container{
  text-align: left;
  margin-bottom: 48.5px;
  left: -161px;
  position: relative;
}
.lf-logo-container div{
  display: inline-block;
}
.copyright-text{
  border-top: solid 1px #c8c8c8;
  padding-top: 10px;
  width: 173px;
  text-align: left;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #767676;
}
.homepage-btn-container{
  margin-bottom: 205px;
}

@media screen and (max-width:525px){
  .btn-main{
    display: block;
    margin-bottom: 20px;
  }
  .lf-logo-container{
    text-align: left;
    left: 0;
  }
  .btn-find-command{
    margin-right: 0;
  }
  .homepage-btn-container{
    margin-bottom: 100px;
  }
  .page-container{
    padding-top: 60px;
  }
}

.input-clean {
  border-radius: 4px;
  background-color: #f4f5f7;
  border:none;
  padding-top: 11px;
  padding-bottom: 11px;
  max-width: 300px;
}
.label-clean{
  font-family: 'LatoLatin';
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #4a4a4a;
  margin-bottom: 14px;
}

.center-text {
  text-align: center;
}

/* do not group these rules */
.form-control::-webkit-input-placeholder {
  font-family: 'LatoLatin';
  font-size: 14px;
  color: #999999;
}
.form-control:-moz-placeholder {
  /* FF 4-18 */
  font-family: 'LatoLatin';
  font-size: 14px;
  color: #999999;
}
.form-control::-moz-placeholder {
  /* FF 19+ */
  font-family: 'LatoLatin';
  font-size: 14px;
  color: #999999;
}
.form-control:-ms-input-placeholder {
  /* IE 10+ */
  font-family: 'LatoLatin';
  font-size: 14px;
  color: #999999;
}
.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  font-family: 'LatoLatin';
  font-size: 14px;
  color: #999999;
}
.form-control::placeholder {
  /* modern browser */
  font-family: 'LatoLatin';
  font-size: 14px;
  color: #999999;
}
tr:hover td{
  background:#ebf9f5
}

thead{
  background: #f4f5f7;
}

@media screen and (max-width:925px){
  .lf-logo-container{
    left: 0;
  }
}

button.back-button{
  background-color: #F5F6F8;
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 #dddddd;
  font-size: 14px;
  font-weight: bold;
  background-repeat: no-repeat;
  border: none;
  margin-top: 0px;
  margin-bottom: 0px;
  background-position: 14px 12px;
  margin-right: 0px;
}

.btn-secondary.back-button:hover{
  background-color: #ECEDF0;
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 #dddddd;
  font-size: 14px;
  font-weight: bold;
  background-repeat: no-repeat;
  border: none;
  margin-top: 0px;
  margin-bottom: 0px;
}

.table-button  {
  align-items:center;
  justify-content: center;
  display: inline;
  padding-left: 30px;
  font-size: 14px;
}


.table-button:hover {
  background-color: #eff0f2;
}

td {
  height: 36px;
  padding-top: 5px;
  padding-bottom:5px;
}

td a {
  display: inline-block;
  height:28px;
  width:78px;
}

thead > tr {
  height: 36px;
  font-family: 'LatoLatin';
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #363636;
}

thead > tr > th:first-child, tbody > tr > td:first-child{
  padding-left: 10px;
}

thead > tr > th.actions{
  padding-left: 14px;
}

tbody > tr {
  height: 36px;
  font-family: 'LatoLatin';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #363636;
}

.KPITable tbody > tr {
  font-weight: bold;
}

.KPITable tbody > tr > td:first-child {
  font-size: 14px;
  font-weight: 900;
  width: 40px;
}

.KPITable tbody > tr > td > input.form-control{
  width: 50px;
  height: 26px;
  border-radius: 4px;
  border: none;
  background-color: #f4f5f7;
  font-size: 14px;
  text-align: right;
  color: #2a2a2a;
  display: inline-block;
  float: left;
  margin-right: 5px;
}

tr.hover-enabled:hover td{
  background:#ebf9f5
}
tr.hover-disabled:hover td{
  background:white
}

tr.highlight-light-yellow td{
  background:rgba(255, 252, 148, 0.2)
}
tr.highlight-light-blue td{
  background:rgba(186, 226, 241, 0.2)
}
.page-item {
  margin: 4px;
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 #dddddd;
  border: none;
}

.page-link{
  color: #484848;
  border: none;
  border-radius: 4px;
  width: 30px;
  height: 30px;
  align-items:center;
  justify-content: center;
  display: flex;
  font-family: 'LatoLatin';
  font-size: 14px;
  line-height: 1.14;
  background-color:  #f4f5f7;
}

.page-item.active .page-link {
  background-color: #0052cc;
}

.page-link:hover {
  color: #484848;
}

.clean-dropdown .dropdown-toggle {
  border:none;
  height: 40px;
  width: 300px;
  border-radius: 3px;
  background-color: #f4f5f7;
  font-family: 'LatoLatin';
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #2a2a2a;
  align-items:center;
  display: flex;
  justify-content: left;
  padding-left: 10px;
}

.dropdown-toggle-primary > a.dropdown-toggle{
  height: 44px;
}

.dropdown-toggle-primary > a.dropdown-toggle:hover, .dropdown:hover{
  cursor: pointer;
}

.clean-dropdown.input-dropdown .dropdown-toggle {
  width: auto;
  max-width: 300px;
}

.clean-dropdown .dropdown-toggle{
  background-image: url('/assets/dropdown-icon.png');
  background-size: 9px auto;
  background-repeat: no-repeat;
  background-position: right 15px center;
}

.clean-dropdown .dropdown-toggle::after{
  display: none;
}
.dropdown-toggle::after{
  vertical-align: .155em;
}

.show>.btn-secondary.dropdown-toggle {
  background-color: #f4f5f7;
  color: #2a2a2a;
}

.btn:focus{
  box-shadow: 2px 2px 5px 0 white;
}

.btn-secondary:not(:disabled):not(.disabled):focus, .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled):active:focus{
  box-shadow: none;
  background-color: #0D8E4B;
}

.dropdown-toggle.btn-secondary:not(:disabled):not(.disabled):focus, .dropdown-toggle.btn-secondary:not(:disabled):not(.disabled):active, .dropdown-toggle.btn-secondary:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
  background-color: #F4F5F7;
}

.btn-secondary:hover {
  background-color: #00813e;
}

.clean-dropdown.dropdown a{
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.dropdown-toggle.btn-secondary:hover, .clean-dropdown.dropdown a:hover{
  background-color: #ECEDF0;
  color: #2A2A2A;
}

.clean-dropdown .btn-secondary:active:focus {
  background-color: #f4f5f7;
  color: #2a2a2a;
  border: none
}
.form-control{
  height: 44px;
  font-size: 14px;
  color: #2a2a2a;
}

.card-body{
  padding: 0 0.35rem;
}
.logoImg{
  max-width: 240px;
}
.materialToggle {
  display: inline-block;
  position: relative;
  margin: 0 0 10px 20px;
  font-size: 16px;
  line-height: 24px;
}
.materialToggleInput {
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 20px;
  opacity: 0;
  z-index: 0;
  display: inline-block;
  float: left;
}
.materialToggleLabel {
  display: block;
  padding: 0 0 0 44px;
  cursor: pointer;
}

.materialToggleLabel:before {
  content: '';
  position: absolute;
  top: 5px;
  left: 0;
  width: 32px;
  height: 14px;
  background-color: #c6c6c6;
  border-radius: 14px;
  z-index: 1;
  transition: background-color 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}

.materialToggleLabel:after {
  content: '';
  position: absolute;
  top: 2px;
  left: 0;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 14px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  z-index: 2;
  transition: all 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: left, background-color;
}

.materialToggleInput:checked + .materialToggleLabel:before {
  background-color: #e3fcef;
}

.materialToggleInput:checked + .materialToggleLabel:after {
  left: 12px;
  background-color: #008b57;
}

.danger-button {
  margin: 0px auto;
  background-color:  #b9281e;
  border: none;
  box-shadow: 0 2px 10px 0 #dddddd;
}

.danger-button:hover {
  background-color:  #a02310;
}

.back-button {
  margin: 0px auto;
  background-color: rgba(9, 30, 66, 0.04);
  border: none;
  box-shadow: 0 2px 10px 0 #dddddd;
}

.back-button:hover {
  background-color: rgba(9, 30, 66, 0.08);
}

.btn-secondary.back-button:not(:disabled):not(.disabled):focus, .btn-secondary.back-button:not(:disabled):not(.disabled):active, .btn-secondary.back-button:not(:disabled):not(.disabled):active:focus{
  background-color: #F4F5F7;
}

.input-clean.kpi-date-picker{
  background-image: url('/assets/datepicker-icon.png');
  background-size: 18px;
  background-position: 18px center;
  background-repeat: no-repeat;
  padding-top: 8px;
  padding-bottom: 8px;
}

.input-dropdown .placeholder, .input-dropdown .placeholder:hover{
  color: #999999;
}

@media (max-width:767px){
  .input-clean {
    max-width: 100%;
  }
  .clean-dropdown.input-dropdown .dropdown-toggle{
    max-width: 100%;
  }
}
@media (max-width: 400px){
  .form-name-text{
    max-width: 150px;
  }
}

.btn-save{
  margin-right: 20px;
  font-size: 14px;
}

.btn-save.enabled{
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 #dddddd;
  background-color: #039a57;
  font-weight: bold;
  border: none;
}

.btn-save.enabled > span {
  margin-left: 15px;
}

.search-input{
  width: 300px;
  margin-left: 0px;
}

.search-button {
  display: inline-flex;
  justify-content: flex-end;
  position: absolute;
  align-items:center;
  width:100%
}

.btn-form-group{
  margin-top:36px;
}
td.contentRight, th.contentRight{
  text-align: left;
  width: 120px;
}

span.breakBlock{
  display: none;
}
@media (max-width: 1480px){
  .vinci-logo img.truckLogo{
    max-width: 100%;
  }
}
@media (max-width: 1440px){
  button.back-button{
    margin-right: 15px;
  }
}
@media (max-width: 1300px){
  .recharts-surface{
    max-width: 100%;
  }
  .recharts-legend-wrapper{
    padding: 12px 20px;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 8px;
  }
  .vinci-logo img{
    max-width: 440px;
  }
  .page-container.authPage {
    padding-top: 100px;
    padding-bottom: 60px;
  }
}
@media (max-width: 1100px){
  .titleBlock{
    flex-basis: auto;
    margin-bottom: 6px;
    width: calc(100% -  80px);
  }
}

@media (max-width: 992px){
  .searchPage .search-input{
    width: 100%;
    max-width: 100%;
  }
  .searchPage .search-button{
    justify-content: flex-start;
  }
  .searchPage .titleBlock{
    height: auto;
  }
  .authFormWrapper{
    padding-top: 40px;
  }
  .vinci-logo img{
    max-width: 300px;
  }
}
@media (max-width: 990px){
  .detailsBlock table tr{
    width: 100%;
    display: inline-block;
    height: auto;
    border-bottom: 0;
  }
  .detailsBlock table{
    margin-bottom: 0;
  }
  tbody > tr.productLine{
    display: block;
    width: 158%;
    height: auto;
  }
  .productLine .detailsBlock table tr{
    display: table-row;
  }
  td.productLabel,
  td.detailsBlock{
    display: block !important;
    height: auto;
  }
  .detailsBlock table tr:first-child td:first-child{
    border: none;
  }
  span.customLabel{
    margin-left: 0;
  }
  .navbar.fixed-left{
    z-index: 3;
  }
  span.breakBlock{
    display: block;
    width: 100%;
  }
  .footerText .row{
    display: block;
  }
  .footerText .footerColInfo{
    display: contents;
    width: auto;
  }
  .footerText .footerColInfo span{
    margin-right: 4px;
  }
  .graphWrapper .col-md-6{
    flex: 0 0 100%;
    max-width: 100%;
  }
  .customFieldMobile .materialToggle{
    margin: 0 0 10px 8px;
    height: 12px;
    display: block;
  }
  .customFieldMobile strong{
    position: relative;
    top: 3px;
  }
}
@media (max-width: 935px){
  .kpiIndicator > div{
    padding-left: 25px;
  }
}
@media (max-width: 890px){
  .hideField{
    display: none;
  }
}
@media (max-width: 880px){
  .rowWrapper .col-lg-20{
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .col-lg-20 .float-right{
    float: none !important;
  }
  .col-lg-20.close-btn-container .float-right{
    float: right !important;
  }
  .col-lg-20 input{
    max-width: 100% !important;
    width: 100% !important;
    float: none !important;
  }
  .btn.right-aligned {
    margin-left: 0;
    margin-top: 0;
  }
  button.back-button{
    height: 46px !important;
    margin-right: 0;
  }
  .col-lg-20 > div{
    width: auto;
    display: inline;
  }
  .modal-footer .btn.right-aligned{
    margin-left: 20px;
  }
}
@media (max-width: 816px){
  .customFieldMobile{
    min-width: 80px;
  }
}
@media (max-width: 768px){
  .container{
    max-width: 100%;
  }
  .search-button .btn{
    margin-top: 0;
  }
  .kpiIndicator > div{
    float: none;
    display: block;
  }
  span.customLabel {
    margin-left: 0;
    float: none;
    display: block;
  }
  .noPadding.col-md-12{
    padding-left: 0;
    padding-right: 0;
  }
  .form-name-text{
    padding-left: 0;
  }
  .vinci-logo{
    border: none;
    text-align: center;
    padding-right: 0;
    padding-bottom: 0;
  }
  .vinci-logo img{
    max-width: 300px;
    margin: auto;
    float: none;
  }
  .vinci-logo img.truckLogo{
    display: none;
  }
}
tr.showTr{
  display: none !important;
}
@media (max-width: 580px){
  .content-lg .colBlock{
    flex-basis: auto !important;
    display: block;
    padding: 0;
  }
  .search-input{
    width: 100%;
  }
  .search-button{
    display: block;
    position: static;
    margin-top: 10px;
  }
  .search-button button{
    display: block;
    width: 100% !important;
  }
  .py-4{
    padding-top: 0 !important;
  }
  .alert{
    padding-left: 40px !important;
  }
  .alert-message{
    font-size: 14px;
  }
  .detailsBlock table tr{
    width: 50%;
  }
  .detailsBlock table td{
    display: block !important;
    overflow: hidden;
    border-bottom: none;
    height: 30px;
    padding-left: 10px;
  }
  tbody > tr.productLine .table-responsive-sm{
    overflow: hidden;
  }
  tbody > tr.productLine{
    display: block;
    width: 200%;
  }
  td.productLabel, td.detailsBlock{
    width: 100%;
  }
  span.opacityName{
    opacity: 0;
    display: block;
  }
  .productLine .detailsBlock table tr{
    display: block;
    float: left;
  }
  tr.hideTr{
    display: none !important;
  }
  tr.showTr{
    display: block !important;
  }
  tr.showTr td{
    padding-bottom: 10px;
  }
}
@media (max-width: 550px){
  .productLine .detailsBlock table tr td span.prodName{
    width: 100%;
  }
}
@media (max-width: 480px){
  .page-container{
    padding-left: 20px;
    padding-right: 20px;
  }
  .page-container.searchPage{
    padding: 45px 0 0;
  }
  .page-container.searchPage iframe{
    width: 100% !important;
  }
  footer.footerText{
    padding-right: 12px;
  }
  .row.rowWrapper{
    margin-right: -15px;
    margin-left: -15px;
  }
  thead > tr > th:first-child, tbody > tr > td:first-child {
    padding-left: 6px;
  }
  .hideMobile{
    display: none;
  }
  .rowWrapper .titleBlock{
    margin-bottom: 10px;
    font-size: 28px;
  }
  .lf-main-text{
    font-size: 22px;
  }
  .form-name-text{
    max-width: calc(100% - 50px);
  }
  nav.navbar.fixed-left{
    z-index: 200;
  }
  ul.side-menu-container{
    display: block;
    height: calc(100vh - 70px);
    margin-top: 0;
    padding-top: 16px;
  }
  .rowWrapper .table-button{
    padding-left: 24px;
    padding-right: 2px;
  }
  span.customLabel{
    margin-left: 0;
    display: inline-block;
    padding: 4px 3px;
    width: auto;
    float: none;
  }
  #app{
    min-height: calc(100vh - 50px);
  }
  .detailsBlock table td{
    padding-left: 6px;
  }
  .kpiIndicator > div {
    padding-left: 0;
  }
  .right-aligned.btn.btn-secondary{
    padding-left: 30px;
    padding-right: 18px;
    margin-right: 0;
  }
  .modal-footer .btn.right-aligned{
    padding: .32rem .75rem;
    margin-bottom: 0;
  }
  .navbar-toggler{
    position: absolute;
  }
  tbody > tr.productLine{
    width: 200%;
  }
  td.productLabel, td.detailsBlock{
    width: 100%;
  }
}
@media (max-width: 454px){
  .productLine .detailsBlock table tr td span.prodName {
    white-space: normal;
  }
}
@media (max-width: 430px){
  .KPITable tbody > tr > td > input.form-control{
    margin-right: 5px;
    width: 30px;
    padding: 5px 2px;
  }
  .customFieldMobile{
    min-width: 0;
  }
  .vinci-logo img{
    max-width: 230px;
  }
  .page-container.authPage{
    padding: 30px 0 10px;
  }
}
@media (max-width: 359px){
  .clean-dropdown .dropdown-toggle{
    width: 278px;
  }
  .mobileTextLimit{
    width: 86px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.dropdown-menu {
  max-height: 500px;
  overflow: auto;
}

.warning-alert {
  background-image: url('/assets/warning-icon.png');
  background-size: 20px auto;
  background-position: 20px center;
  background-repeat: no-repeat;
  padding-left: 60px;
  border: none;
}

.error-alert {
  background-image: url('/assets/error-icon.png');
  background-size: 20px auto;
  background-position: 20px center;
  background-repeat: no-repeat;
  padding-left: 60px;
  border: none;
}

.success-alert-bg{
  background-image: url('/assets/success-icon.png');
  background-size: 20px auto;
  background-position: 20px center;
  background-repeat: no-repeat;
  padding-left: 60px;
  background-color: #e3fcef;
  border: none;
  font-family: 'LatoLatin';
  font-size: 16px;
  font-weight: bold;
  color: #008b57;
}

.close-btn {
  background-image:  url('/assets/success-close-icon.png');
  background-size: 12px;
  background-repeat: no-repeat;
  background-color: transparent;
  border-radius: 4px;
  border: none;
  background-position: center center;
  margin: 0px;
  width: 30px;
  height: 30px;
}

.close-btn:hover{
  background-color: #daefe9;
}

.close-btn:focus{
  background-color: transparent;
}
/* right 20px top 11px; */

.error-close-btn-container {
  display: grid;
  text-align: center;
  align-content: top;
  justify-content: left;
}

.error-close-btn {
  background-image:  url('/assets/error-close-icon.png');
  background-size: 12px;
  background-repeat: no-repeat;
  background-color: transparent;
  border-radius: 4px;
  border: none;
  background-position: center center;
  margin: 0px;
  width: 30px;
  height: 30px;
}

.error-close-btn:hover{
  background-color: #E6D2CD;
}

.btn-secondary.error-close-btn:not(:disabled):not(.disabled):focus, .btn-secondary.error-close-btn:not(:disabled):not(.disabled):active, .btn-secondary.error-close-btn:not(:disabled):not(.disabled):active:focus, .error-close-btn:active{
  background-color: #A81010;
}

.alert{
  position: relative;
  padding-right: 40px;
  padding: 0.5rem 1.25rem;
}
.alert-message{
  display: grid;
  align-content: center;
  justify-content: left;
  padding-left: 40px;
}

.close-btn-container{
  position: absolute;
  right: 15px;
  top: 5px;
}

.alert-info {
  background-color: #dfecfe;
  color: #0c56c9;
  border: none;
}

.info-alert-bg{
  background-image: url('/assets/info-icon.png');
  background-size: 20px auto;
  background-position: 20px center;
  background-repeat: no-repeat;
  padding-left: 20px;
  border: none;
  font-family: 'LatoLatin';
  font-size: 16px;
  font-weight: bold;
}

.tooltip {
  position: absolute;
  background-color:white;
  max-width: 400px;
  opacity: 1;
  box-shadow: 0 0px 5px rgba(39, 39, 39, 0.3);
  border-radius: 4px;
  padding: 10px;
  margin-top: 32px;
}

.fixed-position {
  position: fixed;
}

.inline-block {
  display: inline-block;
}

.react-datepicker__day--highlighted {
  background-color: #d9e6f5 !important;
  color: #000 !important;
}

.react-datepicker__day--selected {
  background-color: #216ba5 !important;
  color: #fff !important;
}

.clear-button-datepicker{
  position: absolute;
  z-index: 1;
  right: 0;
  height: 44px;
  width: 44px;
  margin: 0;
}

.loading-time-status-icon{
  border-radius: 50%;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-left: 6px;
  position: relative;
  z-index: 9;
}